import type ApiClient from '@blg/jsapilib';
import {type Writable, writable} from 'svelte/store';

interface DependenciesStore extends Writable<Dependencies> {
	initDependencies: (apiClient: ApiClient) => Promise<void>;
}

export type Dependencies = {
	[key: string]:
		| Unit[]
		| Tax[]
		| Tag[]
		| DocumentType[]
		| ConfigurableChoice[]
		| WorkflowState[];
};

export interface WorkflowState {
	id: number;
	namespace: string;
	objectName: string;
	title: string;
	states: {
		id: number;
		name: string;
		active: boolean;
		title: string;
		options: {
			color: string;
			extranetTitle: string;
		};
	}[];
}

export interface WorkflowStates {
	id: number;
	name: string;
	active: boolean;
}

export interface Unit {
	abbreviation: string;
	coefficient: number;
	name: string;
}

export interface Tax {
	id: number;
	name: string;
	amount: number;
	title: string;
	active: boolean;
}

export type Tags = {tag: Tag}[];

export interface Tag {
	id: number;
	name: string;
	title: string;
	type: string;
	list: {
		title: string;
		cssClass: string;
		name: string;
	}[];
}

export interface DocumentType {
	id: number;
	active: boolean;
	name: string;
	title: string;
	extranetDiffusion: string; // boolean (ex: 'no')
}

export interface ConfigurableChoice {
	id: number;
	name: string;
	title: string;
	values: {
		title: string;
		value: string;
	}[];
}

function getWorkflowStates(apiClient: ApiClient) {
	const location = ['workflow', 'target'];
	const queryParams = {
		join: ['states']
	};
	return apiClient.listObjects(location, queryParams);
}

function getConfigurableChoices(apiClient: ApiClient) {
	const location = ['core', 'configurableEnum'];
	const queryParams = {};
	return apiClient.listObjects(location, queryParams);
}

function getTags(apiClient: ApiClient) {
	const location = ['core', 'tagGroup'];
	const queryParams = {
		join: ['list.tagCategory']
	};
	return apiClient.listObjects(location, queryParams);
}

function getUnits(apiClient: ApiClient) {
	const location = ['dataRepository', 'unit'];
	const queryParams = {
		field: ['name', 'coefficient', 'abbreviation']
	};
	return apiClient.listObjects(location, queryParams);
}

function getTax(apiClient: ApiClient) {
	const location = ['account', 'tax'];
	return apiClient.listObjects(location);
}

function getDocumentType(apiClient: ApiClient) {
	const location = ['document', 'documentType'];
	return apiClient.listObjects(location);
}

async function initDependencies(apiClient: ApiClient) {
	const promises = {
		workflowStates: getWorkflowStates(apiClient) as Promise<
			WorkflowState[]
		>,
		configurableChoices: getConfigurableChoices(apiClient) as Promise<
			ConfigurableChoice[]
		>,
		tags: getTags(apiClient) as Promise<Tag[]>,
		units: getUnits(apiClient) as Promise<Unit[]>,
		tax: getTax(apiClient) as Promise<Tax[]>,
		documentType: getDocumentType(apiClient) as Promise<WorkflowState[]>
	};

	const response = await Promise.all(Object.values(promises));
	const dependencies: Dependencies = {};

	Object.keys(promises).forEach((key, index) => {
		dependencies[key] = response[index];
	});

	return dependencies;
}

function createDependenciesStore(): DependenciesStore {
	const dependenciesStore = writable<Dependencies>({});

	return {
		...dependenciesStore,
		initDependencies: async (apiClient) =>
			dependenciesStore.set(await initDependencies(apiClient))
	};
}

const dependenciesStore = createDependenciesStore();

export default dependenciesStore;
