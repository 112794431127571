import type {ObjectDefinition} from '@blg/api-definition';
import {type Writable, writable} from 'svelte/store';

export interface Definitions {
	[key: string]: NestedDefinition;
}
export interface NestedDefinition {
	fields: Record<string, Field>;
	meta: Meta;
}

export interface Meta {
	title: string;
	type: string;
}

export type InputAttributes = {
	minLength?: number;
	maxLength?: number;
	nullable?: boolean;
	pattern?: string;
	readonly?: boolean;
	required?: boolean;
	decimal?: number;
	step?: number;
	type?: string;
	title?: string;
	hidden?: boolean;
	disabled?: boolean;
	accept?: string[] | null;
	multiple?: boolean;
};
export interface Field extends InputAttributes {
	allowEmbedded?: boolean;
	autoCount?: boolean;
	childHistory?: boolean;
	enumName?: string;
	historized?: boolean;
	inversedBy?: string;
	primaryKey?: boolean;
	relation?: string;
	target?: Target;
	unique?: boolean;
	history?: boolean;

	customFieldOptions?: {
		type: string;
	};
	choices?: {
		[key: string]: string;
	}[];

	getObjectDefinition: () => ObjectDefinition;
	getName: () => string;
	getTitle: (value?: string) => string;
	getType: () => string;
	isIndexed: () => boolean;
	getSubField: () => Field;
	getTargetObject: () => ObjectDefinition;
	getEmbeddedDefinition: () => ObjectDefinition;
	toJson(isRecursive?: boolean, language?: string | null): Field;
	fieldAttributes: Field;
	getChoiceTitleByValue: (value: string) => string;
}

export interface Target {
	namespace: string;
	objectName: string;
}

interface DefinitionsStore extends Writable<Definitions> {
	setDefinitions(definitions: Definitions): void;
}

function createDefinitionsStore(): DefinitionsStore {
	const {subscribe, set, update} = writable<Definitions>();

	return {
		subscribe,
		set,
		update,
		setDefinitions: (definitions: Definitions) => set(definitions)
	};
}

export const definitionsStore = createDefinitionsStore();
