import type ApiClient from '@blg/jsapilib';
import {
	ApiClientDynamicDataFetcher,
	DefinitionHolder,
	DefinitionValidationParams,
	ObjectDefinition,
	ValidationContext
} from '@blg/api-definition';

import {get} from 'svelte/store';

import {
	definitionsStore,
	type Definitions,
	type NestedDefinition
} from '$stores/definitions';
import {defininitionHolderStore} from '$stores/definitions/definitionHolder';
import {dynamicDataFetcherStore} from '$stores/definitions/dynamicDataFetcher';
import {validationContextStore} from '$stores/definitions/validationContext';
import type ObjectLocation from '@blg/object-location';
import {AuthenticationException} from '@blg/api-exception';

export function getObjectDefinition(
	location: ObjectLocation | string | string[]
): ObjectDefinition {
	// Definition Holder
	const definitionHolder: DefinitionHolder = get(defininitionHolderStore);
	if (!definitionHolder) {
		throw new Error('definitionHolder is required');
	}

	// DynamicDataFetcher
	const dynamicDataFetcher: ApiClientDynamicDataFetcher = get(
		dynamicDataFetcherStore
	);
	if (!dynamicDataFetcher) {
		throw new Error('dynamicDataFetcher is required');
	}

	// ValidationContext
	const validationContext: ValidationContext = get(validationContextStore);
	if (!validationContext) {
		throw new Error('validationContext is required');
	}

	// Object Definition & Field
	return definitionHolder.getDefinition(location) as ObjectDefinition;
}

export async function getAllDefinitions(apiClient: ApiClient) {
	if (!apiClient) {
		throw AuthenticationException.createNoAuth();
	}
	// Definitions
	const definitions: Definitions = await apiClient.getObjectsRefTree(true, {
		static: true
	});
	definitionsStore.setDefinitions(definitions);

	// DefinitionHolder
	const definitionHolder = DefinitionHolder.createAndLinkFromTree(
		definitions,
		DefinitionValidationParams.createLoose()
	);
	defininitionHolderStore.setDefinitionHolder(definitionHolder);

	// Dynamic Fetcher
	const dynamicDataFetcher = new ApiClientDynamicDataFetcher(apiClient);
	dynamicDataFetcherStore.setDynamicDataFetcher(dynamicDataFetcher);

	// Validation Context
	const validationContext = ValidationContext.create({dynamicDataFetcher});
	validationContextStore.setValidationContext(validationContext);
}

export function getLocationDefinition(
	path: string[],
	definitions: Definitions
): NestedDefinition | undefined {
	let currentObject: Definitions | NestedDefinition | undefined = definitions;

	for (const key of path) {
		if (currentObject && key in currentObject) {
			currentObject = (currentObject as Definitions)[key];
		} else {
			return undefined;
		}
	}

	return currentObject as NestedDefinition;
}

export function getFieldDefinition(
	objectDef: ObjectDefinition,
	fieldPath: string
) {
	const fields = fieldPath.split('.');
	if (fields.length === 1) {
		return getFieldDeep(objectDef, fieldPath);
	}
	const firstFieldDef = getFieldDeep(objectDef, fields[0]);
	const targetObject = firstFieldDef.getTargetObject();
	const remainingPath = fields.slice(1).join('.');
	return getFieldDefinition(targetObject, remainingPath);
}

/**
 * Get a field from a definition, even if it's in a child definition. Useful when nested fields
 * @param definition
 * @param fieldName ex: 'billing.status'
 */
export function getFieldDeep(definition: ObjectDefinition, fieldName: string) {
	if (!definition.hasField(fieldName, true)) {
		// not in this def, maybe in a child def ?
		for (const childDefinition of definition.getChildrenDefDeep()) {
			if (childDefinition.hasField(fieldName, true)) {
				// found in a child
				return childDefinition.getField(fieldName);
			}
		}
	}

	// always do the root getField call to get an error in case of the field doesn't exist in inheritance chain, return the root field else
	return definition.getField(fieldName);
}

export function getChoiceTitle(
	location: ObjectLocation | string | string[],
	fieldPath: string,
	name: string
) {
	const def = getObjectDefinition(location);
	const fieldDef = getFieldDefinition(def, fieldPath);
	const title = fieldDef.getChoiceTitleByValue(name);

	return title || name;
}
